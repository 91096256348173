/* eslint-disable */
// @ts-ignore
// @ts-nocheck
import { useGetters, useActions } from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { State, RootActions } from '@/store'
import {
  redirectToSpotifyConnect,
  playOnSpotify,
  pauseOnSpotify
} from '@/utils/spotify'
import { ref, reactive, onMounted } from '@vue/composition-api'

export const useSpotify = (onPlay: () => void, onPause: () => void) => {
  const ready = ref(false)
  const deviceId = ref<string | null>(null)
  const spotify = reactive({
    player: null
  })
  const { accessToken, hasValidAccessToken, song } = useGetters<
    GetterTree<State, State>
  >(['accessToken', 'hasValidAccessToken', 'song'])
  const { setSong } = useActions<RootActions>(['setSong'])

  onMounted(() => {
    window.onSpotifyWebPlaybackSDKReady = async () => {
      if (!hasValidAccessToken.value) {
        redirectToSpotifyConnect()
        return
      }

      spotify.player = new Spotify.Player({
        name: 'binome',
        getOAuthToken: (callback) => callback(accessToken.value)
      })
      spotify.player.addListener('ready', ({ device_id }) => {
        deviceId.value = device_id
        ready.value = true
      })
      spotify.player.addListener('player_state_changed', (state) => {
        if (!state) {
          return
        }

        if (state.paused) {
          setSong(null)
          onPause()
        } else {
          setSong(state.track_window?.current_track?.name ?? null)
          onPlay()
        }
      })
      await spotify.player.connect()
    }
    import('@/lib/spotify-player')
  })

  const play = async () => {
    if (!hasValidAccessToken.value) {
      redirectToSpotifyConnect()
    }
    try {
      if (spotify.player && ready.value) {
        const playerState = await spotify.player.getCurrentState()

        if (!playerState) {
          playOnSpotify(accessToken.value, deviceId.value)
          return
        }
        await spotify.player.resume()
      }
    } catch (error) {
      console.warn(error)
      redirectToSpotifyConnect()
    }
  }

  const pause = () => {
    if (ready.value) {
      pauseOnSpotify(accessToken.value, deviceId.value)
    }
  }

  return {
    ready,
    play,
    pause,
    song
  }
}

export const useSpotifyConnect = (hash: string) => {
  window.onSpotifyWebPlaybackSDKReady = () => {}
  const { setAccessToken, setTokenExpire } = useActions<RootActions>([
    'setAccessToken',
    'setTokenExpire'
  ])
  hash = hash.replace('#', '')
  const connection = [...hash.split('&').values()]
    .map((entry) => entry.split('='))
    .reduce((acc, entry) => {
      acc[entry[0]] = entry[1]
      return acc
    }, {})

  setAccessToken(connection['access_token'])
  const now = new Date()
  setTokenExpire(now.setTime(now.getTime() + connection['expires_in'] * 1000))
}

let PLAYLIST_LINKS: string[] | null = null

interface SpotifyTracks {
  items: Array<{ track: { name: string } }>
}

const random = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max))
}

const getHeaders = (token: string) => ({
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    authorization: `Bearer ${token}`
  }
})

export const redirectToSpotifyConnect = () => {
  if (!process.env.VUE_APP_SPOTIFY_CLIENT_ID) {
    return
  }
  const authorizeURL = new URL('https://accounts.spotify.com/authorize')
  authorizeURL.searchParams.append(
    'client_id',
    process.env.VUE_APP_SPOTIFY_CLIENT_ID || ''
  )
  authorizeURL.searchParams.append('response_type', 'token')
  authorizeURL.searchParams.append('show_dialog', 'false')
  authorizeURL.searchParams.append(
    'scope',
    'streaming,user-modify-playback-state'
  )
  authorizeURL.searchParams.append('redirect_uri', `${location.origin}/spotify`)

  location.href = authorizeURL.toString()
}

export const playOnSpotify = async (token: string, deviceId: string) => {
  if (process.env.VUE_APP_PLAYLIST_URL && !PLAYLIST_LINKS) {
    const playlistResult = await fetch(process.env.VUE_APP_PLAYLIST_URL)
    PLAYLIST_LINKS = (await playlistResult.json()).playlists
  }

  if (!PLAYLIST_LINKS) {
    return
  }

  const playlistId = PLAYLIST_LINKS[random(PLAYLIST_LINKS.length - 1)]
    .split(':')
    .pop()

  const trackResult = await fetch(
    `https://api.spotify.com/v1/playlists/${playlistId}/tracks?fields=items(track(name))`,
    getHeaders(token)
  )
  const tracks = (await trackResult.json()) as SpotifyTracks

  await fetch(
    `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
    {
      method: 'PUT',
      ...getHeaders(token),
      body: JSON.stringify({
        context_uri: PLAYLIST_LINKS[random(PLAYLIST_LINKS.length - 1)],
        offset: {
          position: random(tracks?.items?.length || 0)
        }
      })
    }
  )
}

export const pauseOnSpotify = async (token: string, deviceId: string) => {
  await fetch(
    `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`,
    {
      method: 'PUT',
      ...getHeaders(token)
    }
  )
}
